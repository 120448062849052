import { useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom';

import { useAppDispatch } from "../store/hooks";
import { signIn, registerUser } from "../store/authActions";
import { IRegistrationData } from '../types';

interface UserData {
  userName: string;
  email: string;
  password: string;
  confirmPassword: string;
  quitDate: string;
  avgSmokedDaily: number;
  cigsPerPack: number;
  pricePerPack: number;
};

type UserCredentials = Pick<UserData, 'email' | 'password'>;

function useAuth() {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const attempts = useRef(0);

  const handleSignIn = async ({ email, password }: UserCredentials) => {
    setLoading(true);

    dispatch(signIn({ email, password }))
      .unwrap()
      .then(() => {
        setLoading(false);
        navigate("/dashboard/profile");
      })
      .catch((error) => {
        setLoading(false);
        setError(error);
        attempts.current++;
        console.log('There was a problem with sign-in:', error);
      });
  };

  const handleRegister = async (data: UserData) => {
    setLoading(true);
    
    dispatch(registerUser(data as IRegistrationData))
      .unwrap()
      .then(() => {
        handleSignIn(data);
      })
      .catch((error) => {
        setLoading(false);
        setError(error);
        attempts.current++;
        console.log('There was a problem with registration:', error);
      });
    
    return true;
  };

  const handleDemo = async () => {
    handleSignIn({ email: 'demo@email.com', password: 'Password123!' });
  }

  return {
    loading,
    error,
    attempts: attempts.current,
    handleSignIn,
    handleRegister,
    handleDemo,
  };
}

export default useAuth;