import { Link } from "react-router-dom";
import { CircularProgress } from '@mui/material';

import { useAuth } from '../hooks';

import cloud from './../assets/img/cloudRight.png';
import cloud2 from './../assets/img/cloudLeft.png';

function DemoButton() {

  const { handleDemo, loading } = useAuth();

  return (
    <button onClick={handleDemo} disabled={loading} className="btn demo-btn">
      <span style={loading ? { visibility : 'hidden' } : {}}>Demo</span>
      {loading &&
          <CircularProgress 
            size={25} 
            sx={{ 
              color: 'var(--primary)',
              width: '1em',
              position: 'absolute', 
              top: '30%',
              left: '35%'
            }} 
          />
        }
    </button>
  );
}

export default function Home() {

  return (
    <main className="hero">
      <div className="hero-call">
        <img className="hero-cloud" src={cloud} alt="" />
        <img className="hero-cloud-2" src={cloud2} alt="" />
        <div className="hero-text">
          <h1>When cold turkey isn't enough.</h1>
        </div>
        <div className="hero-buttons">
          <Link to={"/register"}>
            <button className="btn splash-btn">Sign Up</button>
          </Link>
          <DemoButton />
        </div>
      </div>
    </main>
  );
}